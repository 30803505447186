import {
	PaymentMethodTypeEnum,
	IPricesOfProductsResults,
	PlatformsEnum,
	FamilyAccount,
	UsersPassesResultDto,
	LegacyAddUserToFamilyAccountDto,
} from '@bondsports/types';
import { auth } from 'lib/auth';
import { network } from 'lib/network';
import { Answer } from 'types/answer';
import { ProductInCart, ProductInCartEnum } from 'types/cart/productInCart';
import { IValidateParticipantData } from 'types/checkout';
import { RCTeam } from 'types/coreEntites/Team';
import { AddFamilyMember, Family, UpdateFamilyUser } from 'types/family';
import { MembershipItemInCart } from 'types/membership-item-in-cart';
import { IPaymentData, IScheduledPayment, ProductResource, PurchaseProduct } from 'types/payment';
import { ProgramProductInCart } from 'types/program';
import { ReservationObjV2 } from 'types/reservations';
import { ResourcesToProduct } from 'types/resources-to-products';
import { UploadedMedia } from 'types/upload';
import { SignUpGeneral, UserSettings, UserUpdateSettings } from 'types/userSettings';
import { Stripe } from 'stripe';
import { isEmptyNumber, throwErrorIfAny } from 'lib/utils';
import { convertStringToDate } from '../dates';
import { EDateFormats } from '../../types/times';
import dayjs from 'dayjs';
import { Product } from '../../types/product';
import { IError } from '../../types/helpers';
import { calculateTotalPriceWithTax, roundPriceCents } from 'lib/price';
import { Pagination } from '../../types/pagination';

const apiUrl = process.env.BOND_SPORTS_API_URL;
const INVITATION_TOKEN_QUERY_KEY = 'token';
const ITEMS_PER_PAGE_DEFAULT = 15;
const DEFAULT_PAGE = 1;

interface UserSettingsResponse {
	data: UserSettings;
}

type IUserPassesResponse = UsersPassesResultDto | IError;

async function getUserPasses(): Promise<IUserPassesResponse> {
	const options = auth.getAuthHeaders();
	const userId = auth.getUserId();
	const res = await network.get(`${apiUrl}v4/user/${userId}/passes`, options);
	return res;
}

async function getUserSettings_DEPRICATED(presetUserId?: number): Promise<UserSettingsResponse> {
	const options = auth.getAuthHeaders();
	const userId = presetUserId || auth.getUserId();

	const userSettings: UserSettingsResponse = await network.get(`${apiUrl}v1/users/${userId}/settings`, options);
	userSettings.data.birthDate = convertStringToDate(
		String(userSettings.data.birthDate ?? dayjs().format(EDateFormats.YYYY_MM_DD))
	);

	return userSettings;
}

async function putUpdateUserSettings(userData: UpdateFamilyUser, userId?: number) {
	const { headers } = auth.getAuthHeaders();
	const data = {
		profile: userData,
	};

	// api have bugs from backend.
	const response = await network.put(`${apiUrl}v1/users/${userId}/settings`, data, headers);

	return response;
}

async function getUserFamily() {
	const options = auth.getAuthHeaders();
	const userId = auth.getUserId();

	const familySettings: Family[] = await network.get(`${apiUrl}v4/familyAccount/get-by-user/${userId}`, options);

	return familySettings;
}

async function getUserFamilyAccount(familyId: number): Promise<UserSettings[]> {
	const options = auth.getAuthHeaders();
	const familyAccount: UserSettings[] = await network.get(`${apiUrl}v4/familyAccount/get-users/${familyId}`, options);

	return familyAccount;
}

async function putUserFamilyName(id: number, name: string) {
	const { headers } = auth.getAuthHeaders();
	const data = {
		familyAccountId: id,
		familyName: name,
	};

	const response = await network.put(`${apiUrl}v4/familyAccount/${id}`, data, headers);
	throwErrorIfAny(response);
	return response as FamilyAccount;
}

async function postAddFamilyAccount(familyId: number, values: AddFamilyMember) {
	const { headers } = auth.getAuthHeaders();
	const data: LegacyAddUserToFamilyAccountDto = {
		familyAccountId: familyId,
		isUserAdmin: false,
		firstName: values.firstName!,
		lastName: values.lastName!,
		gender: values.gender!,
		birthDate: dayjs(values.birthDate).format(EDateFormats.YYYY_MM_DD),
		sports: [1, 2],
		email: (null as unknown) as string,
	};

	const response = await network.post(`${apiUrl}v4/familyAccount/add-user`, data, headers);

	return response;
}

async function postCreateFamilyAccount(familyName: string) {
	const { headers } = auth.getAuthHeaders();
	const data = {
		userId: auth.getUserId(),
		familyName,
	};

	const response = await network.post(`${apiUrl}v4/familyAccount`, data, headers);

	return response;
}

async function postProfilePicture(values: UploadedMedia, id?: number) {
	const { headers } = auth.getAuthHeaders();
	const userId = id || auth.getUserId();

	const data = {
		file: {
			provider: 'cloudinary',
			mediaKey: 'km7diwmcntuolynstvtg',
			url: values.url,
			fileType: values.fileType,
			fileName: values.fileName,
		},
	};

	const response = await network.post(`${apiUrl}v1/users/${userId}/profile-picture`, data, headers);

	return response;
}

// this is a temp fix until we fix the API that it wont care about the main product !
export type MainProductType = 'membership' | 'program_season';

type AnswerDto =
	| {
			userId: number;
			answers: Answer[];
	  }
	| Answer
	| undefined;

interface PurchasePostData {
	amountToPay: number;
	answers: AnswerDto[];
	parentPurchasedId: number;
	parentPurchasedType: string;
	paymentData?: {
		token: string;
		type: Stripe.PaymentMethod.Type;
	};
	products: PurchaseProduct[];
	purchasingUserId: number;
	isPartialPayment: boolean;
	installments?: IScheduledPayment[];
	platform?: PlatformsEnum | string; // Refactor - types build is broken
}

function createChildToParentProductMap(cartItems: ProductInCart[]): Record<number, number> {
	const childToParentProductMap: Record<number, number> = {};

	for (const cartItem of cartItems) {
		// @typescript-eslint/no-explicit-any
		if (!((cartItem.product as unknown) as { childProductPackages: Product[] }).childProductPackages?.length) {
			continue;
		}

		const children = cartItems.filter(i =>
			(cartItem.product as Product & {
				childProductPackages: (Product & { childProduct: Product })[];
			}).childProductPackages.find(
				(child: Product & { childProduct: Product }) => child.childProduct.id === i.product.id
			)
		);

		for (const child of children) {
			childToParentProductMap[child.product.id] = cartItem.product.id;
		}
	}

	return childToParentProductMap;
}

function createPurchaseData(
	mainProductType: MainProductType,
	cartItems: ProductInCart[],
	purchasingUserId: number,
	pm?: Stripe.PaymentMethod,
	isFreeRegistration?: boolean,
	deposit?: number,
	installments?: IScheduledPayment[]
): PurchasePostData {
	const childToParentProductMap = createChildToParentProductMap(cartItems);

	const productsAsDto: PurchaseProduct[] = cartItems.map(
		item => cartItemToApiData(item, childToParentProductMap) as PurchaseProduct
	);

	const answerAsDto: AnswerDto[] = [];
	cartItems.map(p => {
		if (p.answers && (p.answers[0] as { userId: number; answers: Answer[] })?.userId) {
			if (p.answers && p.answers.length !== 0) {
				answerAsDto.push(p.answers[0]);
			}
		} else {
			answerAsDto.push({
				userId: p.member?.id || 0,
				answers: (p.answers as Answer[]) || [],
			});
		}
		return p;
	});

	const total: number = productsAsDto.reduce((sum, product) => sum + (product.price ?? 0), 0);
	const isDeposit = !isEmptyNumber(deposit);
	const isPartialPayment = isDeposit;
	const amountToPay: number = isDeposit ? (deposit as number) : total;

	const parentPurchasedType = mainProductType;
	const parentPruchaseId =
		parentPurchasedType === 'program_season'
			? (cartItems[0] as ProgramProductInCart).season.id
			: (cartItems[0] as MembershipItemInCart)?.membership?.id ?? 0;
	if (isFreeRegistration) {
		return {
			isPartialPayment,
			purchasingUserId: purchasingUserId,
			parentPurchasedId: parentPruchaseId,
			parentPurchasedType: mainProductType,
			products: productsAsDto,
			answers: answerAsDto,
			amountToPay: roundPriceCents(amountToPay),
		};
	}
	return {
		isPartialPayment,
		purchasingUserId: purchasingUserId,
		parentPurchasedId: parentPruchaseId,
		parentPurchasedType: mainProductType,
		products: productsAsDto,
		platform: 'consumer_checkout',
		paymentData: {
			token: String(pm?.id) || '',
			type: pm?.type || PaymentMethodTypeEnum.CARD,
		},
		answers: answerAsDto,
		amountToPay: roundPriceCents(amountToPay),
		installments,
	};
}

// take all the info from the state, prepare an object for the purchase
// and send it to the server, answers, purchasing user, questionnaire's answers
// etc. etc.
// eslint-disable-next-line require-await
async function postPurchase(
	mainProductType: MainProductType,
	cartItems: ProductInCart[],
	purchasingUserId: number,
	pm?: Stripe.PaymentMethod,
	deposit?: number,
	isFreeRegistration?: boolean,
	installments?: IScheduledPayment[]
) {
	const { headers } = auth.getAuthHeaders();

	const data = createPurchaseData(
		mainProductType,
		cartItems,
		purchasingUserId,
		pm,
		isFreeRegistration,
		deposit,
		installments
	);

	return network.post(`${apiUrl}v4/purchase`, network.addPlatformToBody(data), headers);
}

function postCalculatedPrices(
	mainProductType: MainProductType,
	cartItems: ProductInCart[],
	pm: Stripe.PaymentMethod | undefined,
	purchasingUserId: number,

	deposit?: number,
	isFreeRegistration?: boolean
): Promise<IPricesOfProductsResults[]> {
	const { headers } = auth.getAuthHeaders();

	const data = createPurchaseData(mainProductType, cartItems, purchasingUserId, pm, isFreeRegistration, deposit);

	return network.post(`${apiUrl}v4/product-pricing/products/prices`, data, headers);
}

interface LeaguePurchaseRequestProps {
	data: IPaymentData;
	leagueId: number;
	seasonId: number;
}
async function postLeaguePurchase({ data, leagueId, seasonId }: LeaguePurchaseRequestProps) {
	const { headers } = auth.getAuthHeaders();

	const response = await network.post(
		`${apiUrl}v1/leagues/${leagueId}/season/${seasonId}/sign`,
		network.addPlatformToBody(data),
		headers
	);

	return response;
}

async function getForgotPassword(email: string) {
	const options = auth.getAuthHeaders();

	await network.get(`${apiUrl}auth/password-reset?email=${encodeURIComponent(email)}`, options).catch(() => {
		return null;
	});
}

async function postSignUp(data: SignUpGeneral) {
	const { invitationToken, ...payload } = data;
	const query = new URLSearchParams();

	if (invitationToken) {
		query.append(INVITATION_TOKEN_QUERY_KEY, invitationToken);
	}
	const url = `${apiUrl}auth/signup?${query.toString()}`;

	// eslint-disable-next-line no-return-await
	return await network.post(url, payload); // Return the promise directly
}

async function postUpdateUserSettings(data: UserUpdateSettings, memberId?: number) {
	const { headers } = auth.getAuthHeaders();
	const userId = memberId || auth.getUserId();

	// api have bugs from backend.
	const response = await network.put(`${apiUrl}v1/users/${userId}/settings`, data, headers);

	return response;
}

function getUserReservation(
	userId: number,
	page: number = DEFAULT_PAGE,
	itemsPerPage: number = ITEMS_PER_PAGE_DEFAULT
): Promise<Pagination<ReservationObjV2>> {
	const options = auth.getAuthHeaders();
	return network.get(`${apiUrl}v4/reservations/user/${userId}?page=${page}&itemsPerPage=${itemsPerPage}`, options);
}

async function getUserTeams(): Promise<RCTeam[]> {
	const options = auth.getAuthHeaders();
	const response = await network.get(`${apiUrl}v4/teams/my?limit=1000`, options);

	return response.data;
}

async function getUserRegistration(getExtendedInvoiceData = false) {
	const options = auth.getAuthHeaders();
	const userId = auth.getUserId();
	const response = await network.get(
		`${apiUrl}v4/user/${userId}/registrations?extended=${getExtendedInvoiceData}`,
		options
	);

	return response;
}

export function cartItemToApiData(cartItem: ProductInCart, childToParent?: Record<number, number>) {
	const product: Product = cartItem.product;
	const unitPrice: number = product.currPrice.price;

	//  Fix bad required membership resources
	let resources;
	if (cartItem?.resources) {
		if (!Array.isArray(cartItem?.resources)) {
			resources = productResourceToApiData(cartItem.resources as ResourcesToProduct);
		} else if (Array.isArray(cartItem?.resources)) {
			resources = productResourceToApiData(cartItem.resources[0] as ResourcesToProduct);
		} else {
			resources = cartItem.resources;
		}
	}

	if (!cartItem.ordinal) {
		throw new Error(`missing item's ordinal [internal error]`);
	}
	if (!unitPrice && unitPrice !== 0) {
		throw new Error(`missing item's price`);
	}
	if (!cartItem.member) {
		throw new Error(`missing member`);
	}

	let quantity: number | undefined;
	switch (cartItem.type) {
		case ProductInCartEnum.ADDON:
			quantity = (cartItem.resources as ResourcesToProduct)?.quantity ?? undefined;
			break;
		default: {
			if ((cartItem as MembershipItemInCart).membership) {
				quantity = 1;
			}
		}
	}

	return {
		id: product.id,
		ordinal: cartItem.ordinal,
		unitPrice,
		price: calculateTotalPriceWithTax(unitPrice, product.isTaxInclusive, product.tax, quantity),
		parentOrdinal: cartItem.parentOrdinal ?? undefined,
		resources,
		userId: cartItem.member.id,
		parentProductId: childToParent ? childToParent[product.id] : undefined,
		quantity,
	};
}

export function productResourceToApiData(resource?: ResourcesToProduct) {
	let result: ProductResource[] = [];
	if (resource?.resources) {
		const resourceType = resource.resourceType;
		// maps the ResourceObject to a DTO that the API expect
		result = resource.resources.map(resource => ({
			type: resourceType,
			id: resource.id,
		}));
	}

	return result;
}

const deletePaymentMethod = (userId: number, paymentMethodId: string) => {
	const { headers } = auth.getAuthHeaders();
	return network.delete(`${apiUrl}v4/payment/${userId}/methods/${paymentMethodId}`, {}, headers);
};

async function validateParticipant(data: IValidateParticipantData) {
	const { headers } = auth.getAuthHeaders();
	const response = await network.post(`${apiUrl}v4/purchase/validate-participant/`, data, headers);
	return response;
}
function getUserSettings(userId: number) {
	const { headers } = auth.getAuthHeaders();
	return network.get(`${apiUrl}v4/user/${userId}/settings`, { headers });
}

export const userApi = {
	getUserPasses,
	getUserSettings_DEPRICATED,
	getUserFamilyAccount,
	putUpdateUserSettings,
	postCreateFamilyAccount,
	getUserFamily,
	putUserFamilyName,
	postAddFamilyAccount,
	postProfilePicture,
	postPurchase,
	getUserTeams,
	postLeaguePurchase,
	getForgotPassword,
	postSignUp,
	postUpdateUserSettings,
	getUserReservation,
	getUserRegistration,
	postCalculatedPrices,
	deletePaymentMethod,
	validateParticipant,
	getUserSettings,
};
