/**
 * A wrapper around the browser `fetch` API:
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 */

import { CustomError } from 'types/helpers';
import { PlatformsEnum } from '@bondsports/types';
import { EAllSettledStatus, IAllSettledResponse } from 'types/network';

async function handleHttpResponse(response: Response) {
	const contentType = response.headers.get('content-type');

	const responseBody = contentType?.includes('application/json') ? await response.json() : await response.text();

	/**
	 * Handle a success response.
	 * Anything which isn't between 200-300 HTTP status will throw an error.
	 */
	if (response.status >= 200 && response.status < 300) {
		return responseBody;
	}

	const errorMsg = responseBody.error || response.statusText || response.status;
	const error: CustomError = new Error(errorMsg);

	error.meta = {
		body: responseBody,
		message: responseBody.error,
		statusCode: response.status,
		statusText: response.statusText,
	};

	throw error;
}

/**
 * @example
 * network.get('users/123');
 * network.get('users/123', { mode: 'no-cors' });
 * network.get('http://jsonplaceholder.typicode.com/users');
 */
async function get(path: string, options = {}) {
	valdiatePath(path);
	const response = await fetch(path, {
		method: 'GET',
		credentials: 'include',
		...options,
	});

	return handleHttpResponse(response);
}

/**
 * @example
 * network.post('users', { name: 'Frodo' });
 *
 * @example
 * network.post('users/123/setName', 'Frodo', {
 *   headers: {
 *     'Content-Type': 'text/plain'
 *   }
 * });
 */
async function post(path: string, data: unknown, headers = {}, options = {}) {
	valdiatePath(path);
	const response: Response = await fetch(path, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		credentials: 'include',
		...options,
	});

	return handleHttpResponse(response);
}

async function put(path: string, data: unknown, headers = {}, options = {}) {
	valdiatePath(path);
	const response: Response = await fetch(path, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: headers,
		credentials: 'include',
		...options,
	});

	return handleHttpResponse(response);
}

async function deleteMethod(path: string, data: unknown, headers = {}, options = {}) {
	valdiatePath(path);
	const response: Response = await fetch(path, {
		method: 'DELETE',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		credentials: 'include',
		...options,
	});

	return handleHttpResponse(response);
}

const valdiatePath = (path: string) => {
	const invalidWords = ['undefined', 'null', Number.NaN.toString()];
	const invalidPath = invalidWords.some(element => {
		if (path.includes(element)) {
			return true;
		}

		return false;
	});

	if (invalidPath) {
		const msg = `The following path = ${path} is invalid`;
		console.error(`msg`);
		throw msg;
	}
};

const addPlatformToBody = (body = {}) => {
	return { ...body, platform: PlatformsEnum.CONSUMER };
};

/**
 * Parses the results of a `Promise.allSettled` call
 * @param results - The results of a `Promise.allSettled` call
 * @returns An object with the fulfilled and rejected promises
 */
const parseAllSettledResponse = (results: PromiseSettledResult<unknown>[]): IAllSettledResponse<unknown> => {
	const response: IAllSettledResponse<unknown> = {
		fulfilled: [],
		rejected: [],
	};

	results.forEach(result => {
		if (result.status === EAllSettledStatus.FULFILLED) {
			response.fulfilled.push(result.value);
		} else {
			response.rejected.push(result.reason);
		}
	});

	return response;
};

export const network = { get, post, put, delete: deleteMethod, addPlatformToBody, parseAllSettledResponse };
