import { ColorInput, TinyColor } from '@ctrl/tinycolor';
import { css } from '@emotion/react';
import { IColors } from 'types/colors';
import { ColorProps } from 'types/coreEntites/Organization';
import { breakpoints, gridMaxWidth, gridPadding } from './theme';

/**
 * Media Query helpers
 * See usage examples in `docs/styling.md`
 */

export const mediaUp = (screenSize: string) => `@media (min-width: ${screenSize})`;
export const mediaDown = (screenSize: string) => `@media (max-width: ${screenSize})`;
export const mediaDownVertical = (screenSize: string) => `@media (max-height: ${screenSize})`;
export const mediaBetween = (minScreenSize: string, maxScreenSize: string) =>
	`@media (min-width: ${minScreenSize}) and (max-width: ${maxScreenSize})`;
/** CSS media query for anything below tablet screen. */
export const mobileOnly = mediaDown(`${breakpoints.tablet - 1}px`);
/** CSS media query for smallest tablet screen and above. */
export const tabletUp = mediaUp(breakpoints.tabletPx);
/** CSS media query for smallest desktop screen and above. */
export const desktopUp = mediaUp(breakpoints.desktopPx);
/** CSS media query for screens that go above theme's grid. */
export const gridUp = `@media (min-width: ${gridMaxWidth + gridPadding * 2}px)`;
/** CSS media query for screens that go between tablet and our grid width. */
export const tabletToGrid = mediaBetween(breakpoints.tabletPx, `${gridMaxWidth + gridPadding * 2}px`);

/**
 * Color helpers
 */

/**
 * Sets the alpha value on a current color and returns it as an RGBA string.
 */
export function setOpacity(
	// Any valid color that TinyColor accepts
	color: ColorInput,
	// Opacity level (from 0 to 1)
	opacity: number
) {
	return new TinyColor(color).setAlpha(opacity).toRgbString();
}

/**
 * Adding measurements to make a target mobile-friendly and easier to tap.
 *
 * @see
 * [Tap targets are not sized appropriately](https://developers.google.com/web/tools/lighthouse/audits/tap-targets)
 */
export const tapTargetCss = css`
	${mobileOnly} {
		min-height: 48px;
		min-width: 48px;
	}
`;

/**
 * Truncate text.
 */
export const truncateTextCss = (
	// The number of lines to clamp
	lineClamp: number | 'none'
) => css`
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${lineClamp};
`;

export const brandGradientBackgroundCss = (colors: IColors) => css`
	background-image: linear-gradient(90deg, ${colors.bg_system}, ${colors.bg_system});
`;

export const inputCss = (colors: IColors) => css`
	background: ${colors.bg_background_primary};
	color: #888888;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	padding: 1rem;
	border: none;
	outline: none;
`;

export const errorColorCss = (colors: IColors) => css`
	color: ${colors.tx_error};
`;

export const userFormControlCss = (colors: IColors) => css`
	.MuiFormLabel-root {
		// padding: 8px 0;
	}

	.MuiFilledInput-multiline {
		background-color: ${colors.bg_background_primary};
		padding: 3%;

		&:hover {
			background-color: ${colors.bg_background_primary};
		}
	}

	.MuiFormGroup-row {
		justify-content: space-around;
	}
`;
export const sportsIconCss = (colors: IColors) => css`
	.MuiSvgIcon-root {
		width: 45px;
		height: 45px;
		margin-right: 15px;
		color: ${colors.tx_text_primary};
	}
`;

export const sportsNameCss = (colors: IColors) => css`
	font-size: 1.4rem;
	color: ${colors.tx_text_primary};
`;

export function getBrandColors(colors: IColors, brandings: ColorProps[]) {
	const brandingStyling = { ...colors };

	for (const { key, value } of brandings) {
		brandingStyling[key] = value;
	}

	return brandingStyling;
}

export const mobileBreakPoint = 768;

export const heroDescriptionCss = (colors: IColors) => css`
	background-color: ${setOpacity(colors.opacityBackground || '', 0.79)};
	padding: 15px;
	margin-top: -20px;
`;

export const unsanitized = css`
	ol {
		line-height: 1;
		list-style-type: decimal;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
		margin: 0;
	}

	ul {
		line-height: 1;
		list-style-type: disc;

		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
		margin: 0;
	}
`;

export const seasonBoxContainercss = css`
	@media (max-width: ${mobileBreakPoint}px) {
		&.MuiGrid-grid-sm-4 {
			max-width: 100%;
			flex-basis: 100%;
		}
	}
`;

export const gapCss = (gap: number) => css`
	gap: ${gap}px;
`;

export const flexCss = css`
	display: flex;
`;
export const flexColCss = css`
	display: flex;
	flex-direction: column;
`;

export const justifyContentCss = (how: string) => css`
	justify-content: ${how};
`;

export const justifyContentCenterCss = justifyContentCss('center');

export const alignItemsCenter = css`
	align-items: center;
`;

export const marginTopCss = (margin: number) => css`
	margin-top: ${margin}px;
`;

export const marginBottomCss = (margin: number) => css`
	margin-bottom: ${margin}px;
`;

export const formFooterOffsetCss = css`
	form {
		padding-bottom: 60px;
	}
`;

export const paymentMethodsIconCss = (colors: IColors, isDisabled = false) => css`
	color: ${isDisabled ? colors.bg_text_disabled : colors.tx_text_secondary};
	display: block;
`;
/**
 * font-size & line-height set to prevent overlapped text on Safari-mobile (and improves readability in general)
 */
export const responsiveParagraph = css`
	p {
		font-size: 1.5rem !important;
		line-height: 1.2 !important;
	}
`;

export const centerTextCss = css`
	text-align: center;
`;

export const fullWidthCss = css`
	width: 100%;
`;

export const fullMaxWidthCss = css`
	max-width: 100%;
`;

export const paddingCss = (padding: string) => css`
	padding: ${padding};
`;

export const heightCss = (height: number) => css`
	height: ${height}px;
`;

export const svgCss = (width?: string, height?: string, color?: string) => css`
	${color && `color: ${color}`};

	svg {
		${width && !height ? `width: ${width}; height: auto;` : height && !width ? `height: ${height}; width: auto;` : ``};
		${width && height && `width: ${width}; height: ${height};`};
	}
`;

export const marginCss = (margin = '') => css`
	margin: ${margin};
`;

export const lineClampCss = (linesToDisplay: number) => css`
	display: -webkit-box;
	-webkit-line-clamp: ${linesToDisplay};
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-wrap: break-word;
`;
