export const colors = {
	// Zeplin: #twilight-blue
	brandPrimary: '#0d4774',
	brandPrimaryLight: '#155d94',
	brandSecondary: '#f7b500',
	brandSecondaryLight: '#f7c500',
	textColor: '#0f0238',
	black: '#000',
	white: '#fff',
	dangerRed: '#e23401',
	successGreenText: '#00a35a',
	successGreenBg: '#01e27d',
	btnGreenBg: '#52c483',
	searchTabText: '#535353',
	grayText: '#949494',
	formControlBg: '#f3f7fa',
	formInputBg: '#7c9cb4',
	grayBorder: '#e8e8e8',
	lightGreenBorder: '#869097',
	redBorder: '#e02020',
	darkGray: '#535353',
	whiteLine: '#dae6f0',
	opacityBackground: '#0d4774',
	titleText: '#7c9cb4', // that's on purpose the exact same color like brandPrimary
	ligntText: '#A6BCCC',
};

export const newColors = {
	bg_system: '#F7B500',
	bg_text_primary: '#0D4774',
	bg_text_secondary: '#7C9CB4',
	bg_text_teriaty: '#A6BCCC',
	bg_white: '#FFFFFF',
	bg_text_disabled: '#DBE4EB',
	bg_border_primary: '#DAE6F0',
	bg_border_seperator: '#E2E7EB',
	bg_background_primary: '#F3F7FA',
	bg_background_secondary: '#FAFCFD',
	bg_background_overlay: '#7F7F7F',
	bg_background_fields: '#F6F8FA',
	bg_error: '#e02020',
	//-----------------------------------------------------------------
	tx_system: '#F7B500',
	tx_text_primary: '#0D4774',
	tx_text_secondary: '#7C9CB4',
	tx_text_teriaty: '#A6BCCC',
	tx_white: '#FFFFFF',
	tx_text_disabled: '#DBE4EB',
	tx_border_primary: '#DAE6F0',
	tx_border_seperator: '#E2E7EB',
	tx_background_primary: '#F3F7FA',
	tx_background_secondary: '#FAFCFD',
	tx_background_overlay: '#7F7F7F',
	tx_background_fields: '#F6F8FA',
	tx_error: '#e02020',
	//----------------------------------------------------------------
	btnGreenBg: '#52c483',
	successGreenText: '#00a35a',
	successGreenBg: '#01e27d',
	black: '#000',
	opacityBackground: '#0D4774',
	bg_system_gradient: '#F7C500',

	t_green: '#24C875',
	b_green: '#E5F8EE',
};

export const typography = {
	browserBaseFontSize: 16,
	// `px` value, all CSS `rem` values will be relative to this value
	rootFontSize: 10,
	// `body` font-size multiplier of `rootFontSize`
	globalBodyFontSizeBase: 1.8,
	fontPrimary: 'Montserrat',
};

/* Content's grid maximum width */
export const gridMaxWidth = 940;

/* Content's grid padding */
export const gridPadding = 15;

/**
 * Screen breakpoints based on "popularity" guesstimates and statistics derived
 * from annualized monthly Google queries and some fuzzy math.
 *
 * @see
 * [Screen Size.es](http://screensiz.es/) (Using "Device width" for media-queries)
 */
export const breakpoints = {
	// Smallest mobile screen we support
	// Anything below that is unsupported
	mobile: 360,
	mobilePx: '360px',
	// Smallest tablet screen we support
	tablet: 768,
	tabletPx: '768px',
	// Smallest desktop screen we support
	desktop: 1280,
	desktopPx: '1280px',
};

export const tabletBreakpointRem = breakpoints.tablet / typography.rootFontSize;
export const desktopBreakpointRem = breakpoints.desktop / typography.rootFontSize;

export const zLayers = {
	behindAll: -1,
	base: 0,
	aboveAll: 1000,
};
