import trimStart from 'lodash/trimStart';
import { getDay, getYear, format } from 'date-fns';
import { ActivityTime } from 'types/seasons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { EDateFormats } from '../types/times';
import { OptionalDateString } from '../types/strings';

dayjs.extend(utc);

export const START_OF_DAY = '00:00:00';
export const END_OF_DAY = '23:59:59';

const padTo2Digits = (n: number) => (n > 9 ? String(n) : `0${n}`);

export function getCurrentYear() {
	return getYear(Date.now());
}

/**
 * Get today's day number.
 */
export function getToday() {
	return getDay(Date.now());
}

/**
 * Get the month name by the month number
 *
 * @param month - the month number
 */
export function getMonthName(month: number) {
	const monthsNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	return monthsNames[month - 1];
}

// so .. there's a HUGE bug in the API that expects all times passed as if they happened in UTC
// so if something happens in Israel 2021-01-21 at 19:00, you'll notice these:
// "2021-01-21T19:00:00+02:00" --->> breaks the server
// "2021-01-21T19:00:00Z"      --->> makes the server happy
// ALTHOUGH it's wrong - the server's happy about that
export function fixBrokenTZDate(sDateTime: string | Date) {
	let d: Date;
	let isISO = false;
	if (typeof sDateTime === 'string') {
		d = dayjs(sDateTime).toDate();
		isISO = sDateTime.includes('Z');
	} else {
		d = sDateTime;
	}
	if (isISO) {
		return sDateTime;
	}

	const dateStr = `${d.getFullYear()}-${padTo2Digits(d.getMonth() + 1)}-${padTo2Digits(d.getDate())}`;
	const timeStr = `${padTo2Digits(d.getHours())}:${padTo2Digits(d.getMinutes())}:00Z`;

	return `${dateStr}T${timeStr}`;
}

// RC stores in some places days 2-8 instead of 0-6
export function getFixedDayName(day: number, short = true) {
	return getDayName(day - 2, short);
}

/**
 * Get the day name by the day number
 *
 * @param day
 * @param short - boolean if to return first 3 characters
 *                or the full day name
 *
 * @returns string - The day name
 */
export function getDayName(day: number, short = true) {
	const daysNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

	if (short) {
		return daysNames?.[day]?.slice(0, 3);
	}

	return daysNames?.[day];
}

export function getActivityOpenCloseHours(open: string, close: string, dayOfWeek: string) {
	// This constant date has no significance
	// since we want the time only. You can put
	// any valid date you want.
	const openHour = format(new Date(`2020-12-31T${open}`), 'ha');
	const closeHour = format(new Date(`2020-12-31T${close}`), 'ha');

	// Days range from api is: 2-8 (Mon-Sun)
	return `${getDayName(Number(dayOfWeek) - 2)}, ${openHour} - ${closeHour}`;
}

export function getActivityDayTime(daysOfWeek: number[], open: string, close: string) {
	let daysTime = '';
	daysOfWeek.forEach(day => {
		daysTime += `${getDayName(day, true)}, `;
	});
	const openHour = dayjs(`2020-12-31T${open}`).format('h:mmA').replace(':00', '');
	const closeHour = dayjs(`2020-12-31T${close}`).format('h:mmA').replace(':00', '');
	daysTime += ` ${openHour} - ${closeHour}`;
	return daysTime;
}

export function apiDateStringToDate(date: string): Date {
	if (date.endsWith('Z')) {
		return dayjs(date.slice(0, -1)).toDate();
	}

	return date.includes('T') ? dayjs(date).toDate() : dayjs(`${date}T23:59:59`).toDate();
}

/**
 * returns something like Apr 1
 */
export function getDateString(date: Date): string {
	return `${getMonthName(date.getMonth() + 1)} ${date.getDate()}`;
}

export function getDateStringWithYear(date: Date): string {
	return `${getMonthName(date.getMonth() + 1)} ${date.getDate()},
  ${date.getFullYear()}`;
}

export function getFullDateString(date: string) {
	const SUNDAY = 6;
	const dateTime = apiDateStringToDate(date);
	return `${getDayName(dateTime.getDay() - 1 < 0 ? SUNDAY : dateTime.getDay() - 1, true)},
  ${getMonthName(dateTime.getMonth() + 1)} ${dateTime.getDate()},
  ${dateTime.getFullYear()}`;
}

export function getFullDateStringNewFormat(date: string) {
	const dateTime = apiDateStringToDate(date);
	return `${getDayName(dateTime.getDay() - 1 < 0 ? 0 : dateTime.getDay() - 1, true)},
  ${getMonthName(dateTime.getMonth() + 1)} ${dateTime.getDate()},
  ${dateTime.getFullYear()}`;
}

export function getDates(date: string) {
	return getDateString(apiDateStringToDate(date));
}

export function getDatesRange(startDate: string, endDate?: string) {
	const start = apiDateStringToDate(startDate);
	if (!endDate || startDate === endDate) {
		return getDateString(start);
	}

	const end = apiDateStringToDate(endDate ?? '');
	return `${getDateString(start)} - ${getDateString(end)}`;
}

export function getEventDate(date: string) {
	return dayjs(date).format('MMM DD');
}

export function getDatesRangeWithYears(startDate: string, endDate?: string) {
	const start = apiDateStringToDate(startDate);
	if (!endDate) {
		return getDateStringWithYear(start);
	}

	const end = apiDateStringToDate(endDate ?? '');
	return `${getDateStringWithYear(start)} - ${getDateStringWithYear(end)}`;
}

export function getTimes(startDateWithTime: string, endDateWithTime: string) {
	let [, startTime] = startDateWithTime?.split('T') || ['', ''];
	let [, endTime] = endDateWithTime?.split('T') || ['', ''];
	startTime = startTime.slice(0, 8);
	endTime = endTime.slice(0, 8);
	return getTimeRangeDisplay(startTime, endTime);
}

export function getTimesNewFormat(startTime: string, endTime: string) {
	return getTimeRangeDisplay(startTime, endTime);
}

function time24ToAmPm(hour: number, minutes: number): string {
	const amOrPm = hour >= 12 ? 'PM' : 'AM';

	if (hour > 12) {
		hour = hour - 12;
	}
	if (hour === 0) {
		hour = 12;
	}

	return `${hour}:${padTo2Digits(minutes)}${amOrPm}`;
}

export function getTime24ToAmPmDisplay(time: string): string {
	const [hour, minutes] = time.split(':');
	return time24ToAmPm(Number(hour), Number(minutes));
}

export function getTimeRangeDisplay(startTime: string, endTime: string) {
	return `${getTime24ToAmPmDisplay(startTime)} - ${getTime24ToAmPmDisplay(endTime)}`;
}

export function getDateTimeByActivity(activityTime: ActivityTime) {
	return getTimeRangeDisplay(activityTime.open, activityTime.close);
}

export const getDayMonth = (date: string) => {
	const [, month, day] = date.split('-');
	return `${getMonthName(Number(month))} ${trimStart(day, '0')}`;
};

export const getDateYear = (date: string) => {
	const dateYear = apiDateStringToDate(date);
	return ` ${dateYear.getFullYear()}`;
};

export const getDateNextYear = () => {
	return dayjs().add(1, 'year').toDate();
};

export const getYearRange = () => {
	return `${getDateStringWithYear(new Date())} - ${getDateStringWithYear(getDateNextYear())}`;
};

// converts an array of numbers into ranges
// e.g, [0,1,2,4,5] => [[0,2],[4,5]]
function getRanges(array: number[]) {
	const ranges: number[][] = [];
	for (let i = 0; i < array.length; i++) {
		const rstart = array[i];
		let rend = rstart;
		while (array[i + 1] - array[i] === 1) {
			rend = array[i + 1]; // increment the index if the numbers sequential
			i++;
		}
		ranges.push(rstart === rend ? [rstart] : [rstart, rend]);
	}
	return ranges;
}

export function getDaysAndTimeForActivityTimes(activityTimes: ActivityTime[]): string[] {
	// create a map of key: startTime-endTime => value [ days ]
	const byTime = activityTimes.reduce((acc: { [key: string]: ActivityTime[] }, v) => {
		const times = getTimeRangeDisplay(v.open, v.close);
		if (!acc[times]) {
			acc[times] = [];
		}
		acc[times].push(v);
		return acc;
	}, {});

	const daysAndTimes: string[] = [];
	for (const time in byTime) {
		const days = byTime[time].map(activity => Number(activity.dayOfWeek)).sort();
		// we get ranges either of 2 days (such as monday to thursday) == [2,5]
		// or 1 day (meaning no range): monday [2]
		const daysRange = getRanges(days).reduce((acc, range) => {
			acc += acc ? ', ' : '';
			acc += getFixedDayName(range[0]);
			if (range.length === 2) {
				acc += `-${getFixedDayName(range[1])}`;
			}
			return acc;
		}, '');
		daysAndTimes.push(`${daysRange} ${time}`);
	}
	return daysAndTimes;
}

export function getDatesRangeEventPackages(startDate: string, endDate: string) {
	if (startDate === endDate) {
		return dayjs(startDate).format('MMM DD');
	}
	return `${dayjs(startDate).format('MMM DD')} - ${dayjs(endDate).format('MMM DD')}`;
}

//  return Dec 17 - 20, 2022 OR Dec 17 - Jan 3, 2022
export function getMonthDayRange(startDate: string, endDate?: string) {
	const start = apiDateStringToDate(startDate);
	if (!endDate) {
		return getDateString(start);
	}
	const end = apiDateStringToDate(endDate || '');
	const startString = `${getDateString(start)}`;
	const endString = start.getMonth() === end.getMonth() ? end.getDate() : `${getDateString(end)}`;
	const endStringYear = end.getFullYear();

	return `${startString} - ${endString}, ${endStringYear}`;
}

export function convertStringToDate(date: string, format = EDateFormats.YYYY_MM_DD): Date {
	return dayjs(date, format).toDate();
}

export const toDateTimeTemplate = (date: string, time: string) => {
	return `${date}T${time}`;
};

/**
 * Formats Date object
 * @default yyyy-MM-dd
 * @example
 *  const date = new Date();
 *  const formatted = formatDate(date);
 *  console.log(formatted); // 2000-01-15
 */
export const formatDate = (date: Date, dateFormat: EDateFormats = EDateFormats.yyyy_MM_dd): string => {
	return format(date, dateFormat);
};

/**
 * Attaches time at the end of the date string
 * This forces us to let the browser know that there is no need to use timezone calculation based on the
 * user's current location
 *
 * @example
 *  const date = '10-01-2022';
 *  const result = toTimezoneIndependent(date);
 *  console.log(result); // 10-01-2022T00:00:00
 */
export const toTimezoneIndependent = <T>(date: OptionalDateString<T>): OptionalDateString<T> => {
	if (date instanceof Date) {
		return date;
	}
	if (date) {
		return toDateTimeTemplate(date, START_OF_DAY) as OptionalDateString<T>;
	}
	return undefined as OptionalDateString<T>;
};

export function getUTCDateWithFormat(date: Date | string, format: EDateFormats): string {
	return dayjs(date).utc().format(format);
}

export function convertTimeToDate(time: string): Date {
	return dayjs(`1970-01-01T${time}.000Z`).toDate();
}

export function compareTimesBy(
	time1: string,
	time2: string,
	comparator: (time1: Date, time2: Date) => boolean
): boolean {
	return comparator(convertTimeToDate(time1), convertTimeToDate(time2));
}