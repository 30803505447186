import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useRouterScroll() {
	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = () => {
			window.scrollTo(0, 0);

			if (window.parent !== window) {
				window.parent?.postMessage({ action: 'routeChangeComplete' }, '*');
			}
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);
}
