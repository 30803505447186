import { IResponse } from 'types/error';

export function isEvenNuber(number: number) {
	return number % 2 === 0;
}

export function getBrowserName() {
	if (navigator.userAgent.includes('Edge') && navigator.appVersion.includes('Edge')) {
		return 'Edge';
	} else if (navigator.userAgent.includes('Opera') || navigator.userAgent.includes('OPR')) {
		return 'Opera';
	} else if (navigator.userAgent.includes('Chrome')) {
		return 'Chrome';
	} else if (navigator.userAgent.includes('Safari')) {
		return 'Safari';
	} else if (navigator.userAgent.includes('Firefox')) {
		return 'Firefox';
	}
	return 'unknown';
}

export function download(dataurl: string, filename: string) {
	const link = document.createElement('a');
	link.href = dataurl;
	link.target = '_blank';
	link.download = filename;
	link.click();
}

export const throwErrorIfAny = (response: IResponse) => {
	if (response.err) {
		throw response.rawErr ?? response.err;
	}
};

export const isEmptyNumber = (number?: number | null) => {
	return number === null || number === undefined;
};
