import { atom } from 'recoil';

type THistory = string[];

const History = atom<THistory>({
	key: 'history',
	default: [],
});

export const historyStore = {
	History,
};
