import { Fragment, useEffect } from 'react';
import { Global } from '@emotion/react';
import { ThemeProvider } from '@material-ui/core/styles';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { RecoilRoot, useRecoilState } from 'recoil';
import { useColors } from 'hooks/useColors';
import { useTypography } from 'hooks/useTypography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import { globalCss } from 'styles/global';
import useMuiTheme from 'styles/muiTheme';

import PageHead from 'components/shared/PageHead/PageHead';
import RecoilDebugObserver from 'components/shared/RecoilDebugObserver/RecoilDebugObserver';
import useRouterScroll from 'hooks/useRouterScroll';

import { historyStore } from 'stores/historyStore';
import { BnProvider } from 'bondsports-utils';
import { BnProvider as BnProviderV2 } from '@bondsports/utils';
import { useIntercom } from 'hooks/useIntercom';
import { expander, EBrandingColorsOptions } from 'types/coreEntites/Organization';

// simple-react-lightbox is brought using require since it doesn't support typescript
// eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-commonjs
const SimpleReactLightbox = require('simple-react-lightbox').default;

/**
 * This component wraps all pages.
 * Good for global configurations.
 *
 * https://nextjs.org/docs/advanced-features/custom-app
 * https://nextjs.org/docs/basic-features/data-fetching
 */

function CustomApp({ Component, pageProps, ...props }: AppProps) {
	useRouterScroll();
	const { metadata } = pageProps;

	return (
		<Fragment>
			{metadata && <PageHead {...metadata} />}
			<RecoilRoot override={false}>
				<AppProvider Component={Component} pageProps={pageProps} {...props} />
			</RecoilRoot>
		</Fragment>
	);
}

export default CustomApp;

const AppProvider = ({ Component, pageProps }: AppProps) => {
	const { colors, setColors } = useColors();
	const { typography } = useTypography();
	const [history, setHistory] = useRecoilState(historyStore.History);
	const muiTheme = useMuiTheme();
	const router = useRouter();

	useEffect(() => {
		const { bncolors } = router.query;
		if (bncolors) {
			const nc = (bncolors as string).split(',');
			const newColors: { [key in EBrandingColorsOptions]?: string } = {};
			nc.forEach(c => {
				const obj = c.split(':');
				const index = Number(obj[0]);
				newColors[expander[index] as EBrandingColorsOptions] = `#${obj[1]}`;
			});
			newColors.opacityBackground = newColors.bg_system;
			if (JSON.stringify(colors) !== JSON.stringify({ ...colors, ...newColors })) {
				setColors({ colors: { ...colors, ...newColors } });
			}
		}
	}, [colors]); // eslint-disable-line react-hooks/exhaustive-deps

	useIntercom();

	useEffect(() => {
		setHistory([...history, router.asPath]);
	}, [router.asPath]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<BnProvider colors={colors} typography={typography}>
			<BnProviderV2 colors={colors} typography={typography} isWhiteLabeld origin="root">
				<Global styles={globalCss(colors)} />
				<ThemeProvider theme={muiTheme}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<SimpleReactLightbox>
							<RecoilDebugObserver />
							<Component history={history} {...pageProps} />
						</SimpleReactLightbox>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</BnProviderV2>
		</BnProvider>
	);
};
