import { userApi } from 'lib/api/userApi';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { IColorsSettings } from 'types/colors';
import { ESteps } from 'types/coreEntites/Organization';
import { UserSettings } from 'types/userSettings';
import { auth } from '../lib/auth';
import { newColors, typography } from '../styles/theme';

export interface ITypography {
	browserBaseFontSize: number;
	rootFontSize: number;
	globalBodyFontSizeBase: number;
	fontPrimary: string;
}

const typographySettings = atom<ITypography>({
	key: 'local-typography',
	default: {
		...typography,
	},
});

const colorsSettings = atom<IColorsSettings>({
	key: 'local-colors',
	default: { colors: newColors },
});

const disclaimerSettings = atom<{ [key in ESteps]?: string }>({
	key: 'disclaimer',
	default: {},
});

const userSettings = atom<UserSettings | null>({
	key: 'userSettings',
	default: null,
});

export interface LoginData {
	isLogin: boolean;
	userToken: string;
	userId: number;
	isCompleteLoginProcess?: boolean;
}

const loginSettings = atom<LoginData | null>({
	key: 'isLogin',
	default: null,
});

function useInitUserSettings() {
	const [userSettings, setUserSettings] = useRecoilState(userStore.userSettings);

	useEffect(() => {
		if (userSettings) {
			return;
		}

		(async () => {
			const userId = auth.getUserId();
			try {
				if (!userId) {
					return;
				}
				const userSettingsData = await userApi.getUserSettings(userId);

				setUserSettings(() => ({
					...userSettingsData,
					id: userId,
				}));
			} catch (error) {
				console.error(error);
			}
		})();
	}, [userSettings, setUserSettings]);

	return userSettings;
}

function useInitLoginSettings() {
	const [loginSettings, setLoginSettings] = useRecoilState(userStore.loginSettings);

	useEffect(() => {
		if (loginSettings) {
			return;
		}
		(() => {
			try {
				const token = auth.getToken();
				const userId = auth.getUserId();

				if (token && userId) {
					setLoginSettings({
						isLogin: true,
						userToken: token,
						userId: userId,
						isCompleteLoginProcess: true,
					});
				} else {
					setLoginSettings({ isLogin: false, userToken: '', userId: 0 });
				}
			} catch (error) {
				console.error(error);
			}
		})();
	}, [loginSettings, setLoginSettings]);

	return loginSettings;
}

export const userStore = {
	userSettings,
	useInitUserSettings,
	loginSettings,
	useInitLoginSettings,
	colorsSettings,
	disclaimerSettings,
	typographySettings,
};
