import {CookiesWorker} from "lib/auth/CookiesWorker";

function getToken(): any {
	return CookiesWorker.getBondUserDetails();
}

function getUserId() : any {
	const jwtTokenDetails = CookiesWorker.getBondUserDetails();

	// eslint-disable-next-line no-prototype-builtins
	if (Object.isExtensible(jwtTokenDetails) && jwtTokenDetails.hasOwnProperty('custom:userId')) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return jwtTokenDetails['custom:userId'];
	}

	return false;
}

function getAuthHeaders() {
	const options = {
		headers: {
			accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		},
	};

	return options;
}

export const auth = {
	getAuthHeaders,
	getUserId,
	getToken,
};
