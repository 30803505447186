/* these enuma are  based on copy from the BO enums, when we will unite the repos we should merged them */

export enum EDateFormats {
	YYYY_MM_DD = 'YYYY-MM-DD', // ISO 8601 format, the standard format acrooss the BO.
	yyyy_MM_dd = 'yyyy-MM-dd',
	SLASHED_DATE = 'YYYY/MM/DD',
	DAY_FORMAT = 'MMM DD, YYYY',
	DAY_OF_WEEK_FORMAT = 'ddd',
	DAY_FORMAT_WITH_DAY_OF_WEEK = 'ddd, MMM DD, YYYY',
	MONTH_DAY_COMMA_YEAR = 'MMM DD, YYYY',
	MMMM_YYYY = 'MMMM YYYY',
	MMM_DD = 'MMM DD',
	ddd_MMM_DD = 'ddd, MMM DD',
	MM_DD_YY_slashed = 'MM/DD/YY',
	MMM_d_yyyy = 'MMM d yyyy',
	MMM_D_YYYY = 'MMM D, YYYY',
	SLASHED_MM_dd_YYYY = 'MM/dd/yyyy',
}

export enum EHoursFormat {
	H24_WITH_SECONDS = 'HH:mm:ss', // 14:24:19
	H24_WITH_MINUTES = 'HH:mm', // 14:24
	H12_WITH_MINUTES = 'hh:mm', // 02:24
	H12_ampm = 'hh:mm a', // 02:24 pm
	H12_AMPM_UPPERCASE = 'hh:mm A', //02:24 PM
}

export enum EDurations {
	DAY = 'day',
	WEEK = 'week',
	MINUTES = 'minutes',
	MONTH = 'month',
	YEAR = 'year',
	YEARS = 'years',
}

export enum EDates {
	DATE = 'date',
}
