import { css } from '@emotion/react';

import { typography } from 'styles/theme';
import { IColors } from 'types/colors';
import { fontFacesCss } from './fontFaces';

export const globalCss = (colors: IColors) => css`
	${fontFacesCss}
	/**
			 * Box model like it should be:
			 * http://www.paulirish.com/2012/box-sizing-border-box-ftw/
			 */
	html {
		box-sizing: border-box;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	/**
   * Reset
   */

	html,
	body {
		margin: 0;
		padding: 0;
		letter-spacing: 0.2px;
		height: 100%;
	}

	#__next {
		height: 100%;

		> div {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}

	html.no-vertical-scroll {
		height: 100%;
		overflow-y: hidden;
	}

	a {
		text-decoration: none;
		color: ${colors.tx_text_primary};
	}

	ul,
	ol {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	textarea {
		resize: none;
	}

	/**
   * Font sizing strategy making 1rem equal 10px for easy usage
   * e.g. 1.6rem = 16px
   */

	:root {
		/* e.g. (10 / 16) * 100 = 62.5% */
		font-size: ${(typography.rootFontSize / typography.browserBaseFontSize) * 100}%;
	}

	/**
   * Default font settings for all generic text
   */

	body {
		font-family: ${typography.fontPrimary};
		font-size: ${typography.globalBodyFontSizeBase}rem;
		color: ${colors.tx_text_primary};
	}

	/**
   * Fix default input font styles not taken from body styles
   */

	input {
		font-size: inherit;
		font-weight: inherit;
	}

	/**
   * Makes the hidden attribute work even when an element has display: flex
   * http://lists.w3.org/Archives/Public/public-whatwg-archive/2014May/0001.html
   */

	[hidden] {
		display: none !important;
	}

	b {
		font-weight: 500;
	}

	/**
   * A fix for WebKit browsers as inputs behave differently on it.
   * Using the text-fill-color, sets coloring right.
   */

	input::placeholder,
	input[disabled] {
		-webkit-text-fill-color: currentColor;
	}

	::selection {
		color: ${colors.tx_white};
		background: ${colors.bg_system};
	}

	// Stripe styling

	.StripeElement {
		width: 100%;
		height: 40px;
		padding: 11px 5px;
		background: ${colors.bg_background_primary};
		font-family: ${typography.fontPrimary};
		font-size: ${typography.globalBodyFontSizeBase}rem;
		color: ${colors.tx_background_primary};
	}

	.flex {
		display: flex;
	}

	.flex-col {
		flex-direction: column;
	}

	.justify-between {
		justify-content: space-between;
	}

	.items-center {
		align-items: center;
	}

	// the font-family must be hardcoded - cause in some font the disc in password isn't working

	input[type='password'] {
		font-family: 'Montserrat';
	}

	.pac-container {
		z-index: 1350 !important;
	}
`;
