import Head from 'next/head';

interface Props {
	/**
	 * Defines the document's title that is shown in a browser's title bar or a
	 * page's tab.
	 */
	title: string;
	/**
	 * A short and accurate summary of the content of the page.
	 * Usually shown within search engine's results.
	 */
	description: string;
}

/**
 * A bag of necessary HTML5 and metadata tags for a page.
 */
function PageHead({ title, description }: Props) {
	return (
		<Head>
			{title && <title>{title}</title>}
			{description && <meta name="description" content={description} />}
		</Head>
	);
}

export default PageHead;
