import { Answer } from '../answer';
import { UserSettings } from '../userSettings';
import { Product } from 'types/product';
import { ResourcesToProduct } from 'types/resources-to-products';

export enum ProductInCartEnum {
	PACKAGE = 'package',
	ADDON = 'addon',
}

export interface ProductInCart {
	ordinal: number;
	parentOrdinal?: number;
	type: ProductInCartEnum;
	product: Product;
	member?: UserSettings;
	addons?: Product[];
	answers?: Answer[] | { userId: number; answers: Answer[] }[];
	resources?: ResourcesToProduct | ResourcesToProduct[];
	parentProductId?: number;
}
