import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { useColors } from 'hooks/useColors';
import { useTypography } from 'hooks/useTypography';
import { setOpacity } from './utils';
import { breakpoints } from 'styles/theme';

/**
 * Material UI customization using a theme.
 * Changing the theme configuration variables is the most
 * effective way to match Material-UI to our needs.
 *
 * @see
 * [Material UI Theme](https://material-ui.com/customization/theming)
 * @see
 * [Material Default Theme](https://material-ui.com/customization/default-theme/)
 */

const useMuiTheme = () => {
	const { colors } = useColors();
	const { typography } = useTypography();
	const muiCustomTheme = createMuiTheme({
		// https://material-ui.com/customization/palette/
		palette: {
			primary: {
				main: colors.bg_text_primary,
			},
			secondary: {
				main: colors.bg_system,
			},
		},

		// https://material-ui.com/customization/typography/
		typography: {
			fontFamily: typography.fontPrimary,
			// Tell UI what's the font-size on the HTML element.
			htmlFontSize: typography.rootFontSize,
		},

		// https://material-ui.com/customization/breakpoints/
		breakpoints: {
			values: {
				xs: 0,
				sm: breakpoints.mobile,
				md: breakpoints.tablet,
				lg: breakpoints.desktop,
				xl: 1920,
			},
		},

		// MUI components overrides
		overrides: {
			MuiTypography: {
				h1: {
					textTransform: 'uppercase',
				},
				h2: {
					textTransform: 'uppercase',
				},
				h3: {
					textTransform: 'uppercase',
				},
				h4: {
					textTransform: 'uppercase',
				},
				h5: {},
				h6: {},
			},
			MuiToolbar: {
				root: {
					backgroundColor: colors.bg_text_primary,
				},
				dense: {
					minHeight: 50,
				},
			},
			MuiAppBar: {
				colorPrimary: {
					backgroundColor: colors.bg_text_primary,
				},
			},

			MuiButton: {
				root: {
					borderRadius: '2px',
				},
				contained: {
					fontWeight: 700,
				},
				containedPrimary: {
					backgroundColor: colors.bg_text_primary,
				},
				containedSecondary: {
					backgroundColor: colors.bg_system,
					color: colors.tx_white,
					'&:hover': {
						boxShadow: `0px 2px 16px 8px ${setOpacity(colors.bg_system || '', 0.2)} !important`,
						backgroundColor: colors.bg_system,
					},
				},
				containedSizeSmall: {
					fontSize: '1.1rem',
					height: '30px',
				},
				containedSizeLarge: {
					fontSize: '1.6rem',
					height: '50px',
				},
				outlined: {
					color: colors.tx_text_teriaty,
				},
				outlinedPrimary: {
					borderColor: 'currentColor',
				},
				outlinedSizeSmall: {
					fontSize: '1.1rem',
					height: '30px',
				},
				outlinedSizeLarge: {
					fontSize: '1.6rem',
					height: '50px',
				},
			},
			MuiRadio: {
				colorPrimary: {
					color: colors.tx_text_primary,
				},
				colorSecondary: {
					color: colors.tx_system,
				},
			},
			MuiCheckbox: {
				colorPrimary: {
					color: colors.tx_text_primary,
				},
				colorSecondary: {
					color: colors.tx_system,
				},
			},
			MuiFilledInput: {
				input: {
					backgroundColor: colors.bg_background_primary,
					padding: '8px',
				},
				inputMarginDense: {
					paddingTop: '8px',
					paddingBottom: '8px',
				},
			},
			MuiInputBase: {
				input: {
					color: colors.tx_text_secondary,
				},
			},
			MuiFormLabel: {
				root: {
					color: colors.tx_text_primary,
				},
			},
		},
	});

	return responsiveFontSizes(muiCustomTheme);
};

export default useMuiTheme;
