import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userStore } from 'stores/userStore';

interface IntercomSettings {
	app_id: string;
	name?: string;
	email?: string;
}

declare global {
	interface Window {
		Intercom: (action: string, settings?: IntercomSettings) => void;
	}
}

export function useIntercom() {
	const userSettings = useRecoilValue(userStore.userSettings);
	const loginSettings = useRecoilValue(userStore.loginSettings);
	const [isVisitedLoggedIn, setIsVisitedLoggedIn] = useState(false);

	const AP_ID = 'r8n5hkbk';

	useEffect(() => {
		if (loginSettings?.isLogin && userSettings?.email) {
			setIsVisitedLoggedIn(true);
			window.Intercom('update', {
				app_id: AP_ID,
				name: `${userSettings?.firstName} ${userSettings?.lastName}`,
				email: userSettings?.email,
			});
		} else {
			if (isVisitedLoggedIn) {
				setIsVisitedLoggedIn(false);
				window.Intercom('shutdown');
				window.Intercom('boot', {
					app_id: AP_ID,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginSettings?.isLogin, userSettings?.email, userSettings?.firstName, userSettings?.lastName]);
}
