import { rcEnums } from 'types/rc.enums';
import { RCMediaObject } from './Media';
import { RCAddress } from './Address';

export enum RCMembershipCanJoin {
	CAN_JOIN = 0,
	CANNOT_ALREADY_MEMBER = 1,
}

export interface RCMembership {
	id?: number;
	appliesTo?: {
		event?: boolean;
		venue?: boolean;
		league?: boolean;
	};
	waiverDoc?: string;
	ordinal?: number;
	creatorId?: number;
	creatorType?: rcEnums.shared.ResourceNameTypeEnum;
	name?: string;
	isActive?: boolean;
	isMandatory?: boolean;
	price?: number;
	details?: string;
	cycleLength?: number;
	cycleType?: 'M' | 'w' | 'y';
	canJoin?: RCMembershipCanJoin;
	// applies only to team update
	toDelete?: boolean;
}

export interface RCPortalConfig {
	colors: {
		mainColor: string;
	};
	newsTicker: {
		text: string;
	}[];
}

export enum ESteps {
	Addons = 'addons',
	Application = 'application',
	Details = 'details',
	Package = 'package',
	Payment = 'payment',
	Teams = 'teams',
	Schedule = 'schedule',
}

export interface IDisclaimer {
	createdAt?: string;
	id?: number;
	key: ESteps;
	organizationId?: number;
	updatedAt?: string;
	value: string;
}

export interface RCWebPortal {
	id: number;
	shortUrl: string;
	bannerImage: RCMediaObject;
	portalConfig: RCPortalConfig;
}

export enum RCLinkedAccountStatus {
	NOT_ACTIVE = 0,
	PENDING = 1,
	ACTIVE = 2,
}

export enum RCOfflinePaymentSetting {
	DISABLED = 1,
	ALLOW_CASH = 2,
	REDIRECT = 3,
	INFO_ONLY = 4,
}

export enum RCPaymentSettingStatus {
	ENABLED = 1,
	DISABLED_REDIRECT = 2,
	DISABLED_INFO_ONLY = 3,
	DISABLED_EMAIL = 4,
}

export enum EBrandingColorsOptions {
	bg_system = 'bg_system',
	bg_text_primary = 'bg_text_primary',
	bg_text_secondary = 'bg_text_secondary',
	bg_text_teriaty = 'bg_text_teriaty',
	bg_white = 'bg_white',
	bg_text_disabled = 'bg_text_disabled',
	bg_border_primary = 'bg_border_primary',
	bg_border_seperator = 'bg_border_seperator',
	bg_background_primary = 'bg_background_primary',
	bg_background_secondary = 'bg_background_secondary',
	bg_background_overlay = 'bg_background_overlay',
	bg_background_fields = 'bg_background_fields',
	bg_error = 'bg_error',
	tx_system = 'tx_system',
	tx_text_primary = 'tx_text_primary',
	tx_text_secondary = 'tx_text_secondary',
	tx_text_teriaty = 'tx_text_teriaty',
	tx_white = 'tx_white',
	tx_text_disabled = 'tx_text_disabled',
	tx_border_primary = 'tx_border_primary',
	tx_border_seperator = 'tx_border_seperator',
	tx_background_primary = 'tx_background_primary',
	tx_background_secondary = 'tx_background_secondary',
	tx_background_overlay = 'tx_background_overlay',
	tx_background_fields = 'tx_background_fields',
	tx_error = 'tx_error',
	btnGreenBg = 'btnGreenBg',
	successGreenText = 'successGreenText',
	successGreenBg = 'successGreenBg',
	black = 'black',
	opacityBackground = 'opacityBackground',
	bg_system_gradient = 'bg_system_gradient',
	t_green = 't_green',
	b_green = 'b_green',
}

export type brandingColorsOptions =
	| 'bg_system'
	| 'bg_text_primary'
	| 'bg_text_secondary'
	| 'bg_text_teriaty'
	| 'bg_white'
	| 'bg_text_disabled'
	| 'bg_border_primary'
	| 'bg_border_seperator'
	| 'bg_background_primary'
	| 'bg_background_secondary'
	| 'bg_background_overlay'
	| 'bg_background_fields'
	| 'bg_error'
	| 'tx_system'
	| 'tx_text_primary'
	| 'tx_text_secondary'
	| 'tx_text_teriaty'
	| 'tx_white'
	| 'tx_text_disabled'
	| 'tx_border_primary'
	| 'tx_border_seperator'
	| 'tx_background_primary'
	| 'tx_background_secondary'
	| 'tx_background_overlay'
	| 'tx_background_fields'
	| 'tx_error'
	| 'btnGreenBg'
	| 'successGreenText'
	| 'successGreenBg'
	| 'black'
	| 'opacityBackground'
	| 'bg_system_gradient'
	| 't_green'
	| 'b_green';

export interface ColorProps {
	createdAt: string;
	id: number;
	key: brandingColorsOptions;
	organizationId: number;
	updatedAt: string;
	value: string;
}

export interface RCOrganization {
	disclaimer: IDisclaimer[];
	brandings: ColorProps[];
	brandingsV2: ColorProps[];
	id: number;
	name: string;
	email: string;
	sports?: number[];
	phoneNumber: string;
	about: string;
	tagline?: string;
	// creator?: RCUser;
	mainMedia?: {
		url: string;
	};
	logo?: RCMediaObject;
	address?: RCAddress;
	// followers?: Array<any>;
	followersCount?: number;
	membershipTypes?: RCMembership[];
	media?: RCMediaObject;
	blog?: string;
	facebook?: string;
	instagram?: string;
	twitter?: string;
	website?: string;
	following?: boolean;
	webPortal?: RCWebPortal;
	braintreeAccount?: RCLinkedAccountStatus;
	stripeAccount?: RCLinkedAccountStatus;
	organizationActivityTypes?: number[];
	organizationAudienceTypes?: number[];
	organizationGenders?: rcEnums.shared.GenderEnum[];
	paymentSettings?: {
		installments?: number;
		onlinePaymentEnabled?: boolean;
		allowCash?: boolean;
		paymentSettingStatus?: RCPaymentSettingStatus;
		bookingRequestOnly?: boolean;
		vettedCustomerDirectBooking?: boolean;
	};
	isClaimed?: boolean;
	questionnaireId?: number;
	membershipQuestionnaireId?: number;
}

export const expander: { [x: number]: brandingColorsOptions } = {
	1: 'bg_system',
	2: 'bg_text_primary',
	3: 'bg_text_secondary',
	4: 'bg_text_teriaty',
	5: 'bg_white',
	6: 'bg_text_disabled',
	7: 'bg_border_primary',
	8: 'bg_border_seperator',
	9: 'bg_background_primary',
	10: 'bg_background_secondary',
	11: 'bg_background_overlay',
	12: 'bg_background_fields',
	13: 'bg_error',
	//-----------------------------------------------------------------
	14: 'tx_system',
	15: 'tx_text_primary',
	16: 'tx_text_secondary',
	17: 'tx_text_teriaty',
	18: 'tx_white',
	19: 'tx_text_disabled',
	20: 'tx_border_primary',
	21: 'tx_border_seperator',
	22: 'tx_background_primary',
	23: 'tx_background_secondary',
	24: 'tx_background_overlay',
	25: 'tx_background_fields',
	26: 'tx_error',

	27: 'bg_system_gradient',

	28: 't_green',
	29: 'b_green',
};
